import '@/assets/scss/index.scss'

// import DefaultPage from './pages'

import ShowCase from './pages/ShowCase'

function App() {
  return <ShowCase />
}

export default App
