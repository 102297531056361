import React, { useEffect, useState } from 'react'
import { TCaseData, TTaxonomyArray } from '@/types'
import { Tabs, TabsProps, Dropdown, MenuProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'

export const caseData: TCaseData[] = window?.postArray?.postArray
export const taxonomyArray: TTaxonomyArray[] = window?.taxonomyArray?.taxonomyArray

export type TSingleCaseprops = {
  item: TCaseData
}
export const TabPaneList = (props: any) => {
  const { taxonomy } = props

  // console.log('🚀 ~ TabPaneList:', taxonomy)

  const SingleCase = (SingleCaseProps: TSingleCaseprops) => {
    const { item } = SingleCaseProps

    return (
      <div className="h-full w-full duration-500 hover:scale-105 rounded-2xl shadow-[0_0px_20px_0px_rgba(3,50,142,0.1)]">
        <a href={item.postLink}>
          <div className="overflow-hidden w-full p-2">
            <img src={item.postImage} alt="" className="w-full h-full aspect-[300/215] object-cover" />
          </div>
          <div className="caseInfo text-center pt-1 px-2 pb-10 bg-[#f9fafa]">
            <h4 className="text-[#3f444b] text-xl my-0">{item.postTitle}</h4>
            <p className="text-[#03328E] my-0">
              {item.postCategoryTitle.map((title, index) => {
                if (index === 0) {
                  return title.CategoryTitle
                }
                return `,${title.CategoryTitle}`
              })}
            </p>
          </div>
        </a>
      </div>
    )
  }

  return (
    <div className="grid grid-cols-2 gap-4 py-10 md:grid-cols-3 md:gap-10">
      {taxonomy !== undefined
        ? caseData
            .filter((item) => item.postCategoryID.some((CategoryID) => CategoryID.CategoryID === taxonomy.CategoryID))
            .map((item) => {
              return (
                <div key={item.postID}>
                  <SingleCase item={item} />
                </div>
              )
            })
        : caseData.map((item) => {
            return (
              <div key={item.postID}>
                <SingleCase item={item} />
              </div>
            )
          })}
    </div>
  )
}

const formattedData = taxonomyArray.map((taxonomy) => ({
  key: taxonomy.CategoryID.toString(),
  label: taxonomy.CategoryTitle,
  children: <TabPaneList taxonomy={taxonomy} />,
}))

const items: TabsProps['items'] = [
  //自訂一個全部分類

  {
    key: '1',
    label: '全部分類',
    children: <TabPaneList />,
  },
  ...formattedData,
]

// 自定义标签栏组件

const CustomTabBar = (props: any) => {
  const { activeKey, panes, onChange } = props
  const [
    windowWidth,
    setWindowWidth,
  ] = useState(window.innerWidth)

  // console.log('panes', panes)
  // console.log('activeKey', activeKey)

  // 添加用于检测屏幕宽度变化的事件处理程序

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    // 添加窗口大小变化事件监听器

    window.addEventListener('resize', handleWindowResize)
    return () => {
      // 在组件卸载时移除事件监听器

      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  if (windowWidth < 810) {
    // 当屏幕宽度小于 810px 时，使用下拉菜单

    const DropdownMenu: MenuProps['items'] = panes.map((pane: any) => ({
      key: pane.props.tabkey,
      label: (
        <a className="text-center underline-offset-0 no-underline" onClick={() => onChange(pane.key)}>
          {pane.props.tab}
        </a>
      ),
    }))

    return (
      <Dropdown
        menu={{
          items: DropdownMenu,
          selectable: true,
        }}
        trigger={['click']}
        className="123">
        <a className="text-center">
          <Space>
            {panes.find((pane: any) => pane.key === activeKey)?.props.tab}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    )
  }
  return (
    <div className="custom-tab-bar flex justify-around border-0 border-solid border-b border-[#d5d8dc]">
      {panes.map((pane: any) => {
        return (
          <div key={pane.key} className={`custom-tab cursor-pointer py-5 font-normal text-base  ${activeKey === pane.key ? 'text-[#03328E] border-0 border-solid border-b-4 border-[#03328E]' : ''}`} onClick={() => onChange(pane.key)}>
            {pane.props.tab}
          </div>
        )
      })}
    </div>
  )
}
const customTabBar: TabsProps['renderTabBar'] = (props) => {
  // console.log(props)

  return <CustomTabBar {...props} onChange={props.onTabClick} />
}

const ShowCase: React.FC = () => <Tabs defaultActiveKey="1" renderTabBar={customTabBar} items={items} className="font-['Noto_Sans_TC']"></Tabs>

export default ShowCase
